<template>
  <div class="mt-6">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">{{$t("settings.fileLibrary")}}</h1>
        <p class="mt-2 text-sm text-gray-700">{{$t("settings.manageFiles")}}</p>
      </div>
      <div class="flex items-end space-x-2">
        <div class="flex flex-col justify-between" >
          <label for="content-type" class=" block text-sm font-medium pb-1 sm:text-sm text-gray-700">{{$t("language")}}</label>
          <select v-model="language" id="content-type" name="model" class=" block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm rounded-md">
              <option v-for="lang in $i18n.availableLocales" :key="lang" :value="lang">{{ lang }}</option>
          </select>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <label for="file-input">
            <div class="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-ifgreen px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-iifgreen-dark focus:outline-none focus:ring-2 focus:ring-ifgreen focus:ring-offset-2 sm:w-auto">
              <PlusIcon class="h-5 w-5 mr-0.5" />
              {{ $t('settings.addFiles') }}
            </div>
          </label>
          <input
            @change="onFileChange"
            class="hidden"
            id="file-input"
            type="file"
            multiple
          />
        </div>
      </div>
    </div>
    <div v-if="filesToUpload && filesToUpload.length" class="overflow-hidden mt-6 bg-white shadow sm:rounded-md border border-gray-200">
      <ul role="list" class="divide-y divide-gray-200">
        <FileUploader 
          v-for="(file, index) in filesToUpload" 
          :key="file.id"
          :file="file"
          :organisation="organisation"
          :language="language"
          @close="closeFileUploader(index)"
          @success="addToTable"
        />
      </ul>
    </div>
    <div v-if="files" class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{$t("name")}}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{$t("type")}}</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6 text-right">
                    {{$t("action")}}
                    <span class="sr-only">{{$t("action")}}</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr v-for="(file, index) in files" :key="file.id" :class="index % 2 === 0 ? undefined : 'bg-gray-50'">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ file.name }}</td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900">{{ file.type }}</td>
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium space-x-1">
                    <a :href="`${baseUrl}${file.path}`" target="_blank" :title="$t('settings.viewFile')" class="rounded-full inline-flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                      <ArrowTopRightOnSquareIcon class="h-5 w-5" aria-hidden="true" />
                    </a>
                    <button  :title="$t('settings.changeName')" @click="editName(file)" class="rounded-full inline-flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                      <PencilIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button  :title="$t('settings.deleteFile')" @click="deleteFile(file, index)" class="rounded-full inline-flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                      <CogIcon v-if="loading === index" class="h-5 w-5 animate-spin" aria-hidden="true" />
                      <TrashIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    ref="modal"
    :title="$t('settings.changeName')"
    :cancel-button-label="$t('cancel')"
  >
    <template #textarea>
      <div class="w-full mt-5">
        <div class="mt-1 relative">
          <input
            v-model="editFileName"
            class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
          />
        </div>
      </div>
    </template>
    <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ifgreen text-base font-medium text-white hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen sm:col-start-2 sm:text-sm" @click="saveName">
      {{$t("accept")}}
    </button>
  </Modal>
</template>
<script>


import FileUploader from '@/components/layout/FileUploader.vue'
import Modal from '@/components/layout/Modal.vue';
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { s3url } from "@/assets/js/helpers.js"
import { TrashIcon, CogIcon, PlusIcon, PencilIcon, ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'
import { mapState } from 'vuex'

export default {
  components: {
    FileUploader,
    Modal,
    TrashIcon,
    CogIcon,
    PencilIcon,
    PlusIcon,
    ArrowTopRightOnSquareIcon,
  },
  data () {
    return{
      files: null,
      filesToUpload: [],
      loading: -1,
      editFileName: null,
      editFileId: null,
      language: this.$i18n.locale
    }
  },
  computed: {
    ...mapState(['organisation']),
    baseUrl() {
      return s3url
    }
  },
  methods: {
    getFiles(){
      this.$store.dispatch('setLoading', true)
      api.get(`/organisations/${this.organisation}/files?language=${this.language}`).then(result => {
        console.log(result)
        this.files = result.data
        this.$store.dispatch('setLoading', false)
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setLoading', false)
      })
    },
    editName(file) {
      this.editFileName = file.name
      this.editFileId = file.id
      this.$refs.modal.show()
    },
    async saveName() {
      this.$refs.modal.close()
      this.$nextTick(() => {
        this.$store.dispatch('setLoading', true)
      })
      await api.patch(`/files/${this.editFileId}`, {name: this.editFileName}, patchHeaders)
      const index = this.files.findIndex(file => {return file.id == this.editFileId})
      this.files[index].name = this.editFileName
      this.$store.dispatch('setLoading', false)
    },
    async deleteFile (file, index) {
      if (confirm(`${this.$t("settings.deleteConfirmText1")} ${file.name} ${this.$t("settings.deleteConfirmText2")}`)) {
        this.loading = index
        try {
          const result = await api.post('/delete-s3-file', {key: file.path})
          console.log(result)
          try {
            const result = await api.delete(`/files/${file.id}`)
            console.log(result)
            this.files.splice(index, 1)
            this.loading = -1
          } catch (error) {
            this.loading = -1
            this.$notify({
              title: `${this.$t("home.barcodeScanner.errorTitle")}`,
              text: `${this.$t("settings.deleteFileLinkError")}`,
              type: "error"
            })
          }
        } catch (error) {
          this.loading = -1
          this.$notify({
            title: `${this.$t("home.barcodeScanner.errorTitle")}`,
            text: `${this.$t("settings.deleteFileError")}`,
            type: "error"
          })
        }
      }
    },
    async onFileChange(e) {
      this.filesToUpload.push(...e.target.files)
    },
    closeFileUploader(index) {
      this.filesToUpload.splice(index, 1)
    },
    async addToTable(file) {
      this.files.unshift(file)
    }
  },
  created() {
    this.getFiles()
  },
  watch: {
    organisation () {
      this.getFiles()
    },
    language () {
      this.getFiles()
    }
  }
}
</script>
