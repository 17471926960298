<template>
<li class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="w-full p-6 space-y-1">
        <div class="bg-white py-5 border-b border-gray-200 ">
            <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div class="ml-4 mt-2">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                         {{heading}}
                    </h3>
                </div>
            </div>
        </div>
        <div class="bg-white py-5 border-b border-gray-200 flex justify-between">
            <div class="flex rounded-md shadow-sm">
                <div class="relative flex items-stretch flex-grow focus-within:z-10">
                    <input v-model="newOption" type="text" name="text" :id="name" class="focus:ring-ifgreen focus:border-ifgreen block w-full rounded-none rounded-l-md sm:text-sm border-gray-300" :placeholder="$t('settings.enterValue')" />
                </div>
                <button @click="addOption" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-3 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
                    <PlusIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                </button>
            </div>
        </div>
        <draggable
          v-model="options"
          :group="name"
          @start="drag=true"
          @end="drag=false"
          :item-key="(element) => { return element }"
        >
          <template #item="{element}">
            <li class="py-4">
              <div class="flex items-center justify-between space-x-4">
                <div class="flex-1 min-w-0">
                  <p class="text-sm font-medium text-gray-900 truncate cursor-move">
                    {{ element }}
                  </p>
                </div>
                <div class="flex items-center">
                  <button @click="removeOption(element)" class=" inline-flex">
                    <MinusCircleIcon class="w-6 h-6" />
                  </button>
                </div>
              </div>
            </li>
          </template>
        </draggable>
        <!--
        <ul class="draggable-list" aria-disabled="true">
            <li v-for="(option,index) in options" :key="index" class="py-4">
                <div class="flex items-center justify-between space-x-4">
                    <div class="flex-1 min-w-0">
                        <p class="text-sm font-medium text-gray-900 truncate">
                            {{option}}
                        </p>
                    </div>
                    <div class="flex items-center">
                        <button @click="removeOption(index)" class=" inline-flex">
                            <MinusCircleIcon class="w-6 h-6" />
                        </button>
                    </div>
                </div>
            </li>
        </ul> -->
    </div>
</li>
</template>
<script>
import { MinusCircleIcon, PlusIcon } from "@heroicons/vue/24/outline";
import draggable from 'vuedraggable'

export default {
  components: {
    MinusCircleIcon,
    PlusIcon,
    draggable
  },
  props: {
    id: Number,
    heading: String,
    name: String,
    initialOptions: String
  },
  emits: ['options-change'],
  data () {
    return{
      options: this.initialOptions,
      newOption: '',
      drag: false
    }
  },
  methods: {
    addOption() {
     this.options.push(this.newOption)
     this.newOption = ""
    },
    removeOption(element){
      const index = this.options.findIndex((option) => { return option === element })
      this.options.splice(index,1)
    }
  },
  watch: {
    options: {
      deep: true,
      handler(){
        this.$emit('options-change', {id: this.id, options: this.options})
      }
    }
  }
}
</script>
