<template>
  <Combobox as="div" v-model="selectedItem" @update:modelValue="$emit('update:modelValue', selectedItem)">
    <ComboboxLabel class="block text-sm font-medium text-gray-700">{{ label }}</ComboboxLabel>
    <div class="relative mt-1">
      <ComboboxInput :placeholder="$t('settings.fileName')" :class="error ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 animate-pulse' : 'border-gray-300 focus:ring-ifgreen'" class="w-full rounded-md border bg-white py-2 pl-3 pr-10 shadow-sm focus:border-ifgreen focus:outline-none focus:ring-1 sm:text-sm" @change="query = $event.target.value" :display-value="(file) => file?.name" />
      <button v-if="selectedItem" @click="clearSelection()" class="absolute inset-y-0 right-8 flex items-center rounded-r-md px-2 focus:outline-none">
        <TrashIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </button>
      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions v-if="filteredFiles.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        <ComboboxOption v-for="file in filteredFiles" :key="file.id" :value="file" as="template" v-slot="{ active, selected }">
          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-ifgreen text-white' : 'text-gray-900']">
            <div class="flex">
              <span :class="['truncate', selected && 'font-semibold']">
                {{ file.name }}
              </span>
              <span :class="['ml-2 truncate', active ? 'text-white' : 'text-gray-500']">
                {{ file.type }}
              </span>
            </div>

            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-ifgreen']">
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
      <div v-if="files.length == 0" class="absolute z-10 mt-1 px-2 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        {{$t("settings.noFilesText1")}} <router-link to="/admin/settings#fileLibrary" class="text-ifgreen">{{$t("settings.noFilesText2")}}</router-link> {{$t("settings.noFilesText3")}}
      </div>
    </div>
  </Combobox>
</template>

<script>
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { TrashIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

export default {
  name: "FileSelect",
  emits: ['update:modelValue'],
  components: {
    TrashIcon,
    CheckIcon,
    ChevronUpDownIcon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions
  },
  props: {
    files: Array,
    modelValue: Object,
    label: {
      type: String,
      default: 'Wybierz plik'
    },
    showAllOptions: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      query: '',
      selectedItem: null,
    }
  },
  computed: {
    filteredFiles () {
      return this.query === '' ? (this.showAllOptions ? this.files : []) : this.files.filter((file) => {
        const name = file.name.toLowerCase().includes(this.query.toLowerCase())
        return name
      })
    }
  },
  methods: {
    clearSelection () {
      this.selectedItem = null
      this.$emit('update:modelValue', this.selectedItem)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.selectedItem = this.modelValue
      }
    }
  }
};
</script>
