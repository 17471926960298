<template>
  <div
    class="overflow-hidden border b-gray-200 bg-white shadow sm:rounded-md mt-6"
  >
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="{id, name, description, activeVersion, language, isActive} in deviceTemplates" :key="id" class="hover:bg-gray-50">
        <div class="px-4 py-4 sm:px-6">
          <div class="flex items-center justify-between">
            <div class="flex-grow">
              <router-link :to="`/admin/device-templates/${id}`">
                <p class="truncate text-sm font-medium text-gray-900">{{ name }} ({{ language.toUpperCase() }})</p>
                <p class="mt-1 text-sm text-gray-500">{{ description }}</p>
              </router-link>
            </div>
            <div class="ml-2 flex items-center flex-shrink-0 space-x-2">
              <div class="text-right">
                <p :class="isActive ? 'bg-ifgreen' : 'bg-red-400'" class="inline-flex rounded-full px-2 text-xs font-semibold leading-5 text-white">
                  {{ isActive ? `${this.$t("active")}` : `${this.$t("inactive")}` }}
                </p>
                <p
                  v-if="
                    !activeVersion.installationFormSchema || activeVersion.installationFormSchema.length == 0 ||
                    !activeVersion.inspectionFormSchema || activeVersion.inspectionFormSchema.length == 0"
                  class="mt-1 text-xs text-red-400">
                  {{$t("template.activateTemplate")}}
                </p>
              </div>
              <router-link :to="`/admin/device-templates/${id}/form-templates/installation`" title="edytuj formularz uruchomienia" class="relative inline-flex items-center rounded-full border border-transparent bg-ifgreen p-2 text-white shadow-sm hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-ifgreen focus:ring-offset-2">
                <ClipboardDocumentListIcon class="relative -top-0.5 -left-0.5 h-6 w-6" aria-hidden="true" />
                <HomeIcon class="bg-ifgreen absolute bottom-1.5 right-1.5 h-4 w-4 rounded-full" aria-hidden="true" />
                <PencilIcon v-if="activeVersion.installationFormSchema && activeVersion.installationFormSchema.length" class="w-5 h-5 p-0.5 text-ifgreen bg-white border border-ifgreen rounded-full absolute -bottom-1.5 -right-1.5" />
                <PlusIcon v-else class="w-5 h-5 p-0.5 text-ifgreen bg-white border border-ifgreen rounded-full absolute -bottom-1.5 -right-1.5" />
              </router-link>
              <router-link :to="`/admin/device-templates/${id}/form-templates/inspection`" title="edytuj formularz przeglądu" class="relative inline-flex items-center rounded-full border border-transparent bg-ifgreen p-2 text-white shadow-sm hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-ifgreen focus:ring-offset-2">
                <ClipboardDocumentListIcon class="relative -top-0.5 -left-0.5 h-6 w-6" aria-hidden="true" />
                <ClockIcon class="bg-ifgreen  absolute bottom-1.5 right-1.5 h-4 w-4 rounded-full" aria-hidden="true" />
                <PencilIcon v-if="activeVersion.inspectionFormSchema && activeVersion.inspectionFormSchema.length" class="w-5 h-5 p-0.5 text-ifgreen bg-white border border-ifgreen rounded-full absolute -bottom-1.5 -right-1.5" />
                <PlusIcon v-else class="w-5 h-5 p-0.5 text-ifgreen bg-white border border-ifgreen rounded-full absolute -bottom-1.5 -right-1.5" />
              </router-link>
              <router-link :to="`/admin/device-templates/${id}/warranty-template`" title="edytuj wzór gwarancji" class="relative inline-flex items-center rounded-full border border-transparent bg-ifgreen p-2 text-white shadow-sm hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-ifgreen focus:ring-offset-2">
                <ClipboardDocumentListIcon class="relative -top-0.5 -left-0.5 h-6 w-6" aria-hidden="true" />
                <ShieldCheckIcon class="bg-ifgreen  absolute bottom-1.5 right-1.5 h-4 w-4 rounded-full" aria-hidden="true" />
                <PencilIcon v-if="activeVersion.warrantySchema" class="w-5 h-5 p-0.5 text-ifgreen bg-white border border-ifgreen rounded-full absolute -bottom-1.5 -right-1.5" />
                <PlusIcon v-else class="w-5 h-5 p-0.5 text-ifgreen bg-white border border-ifgreen rounded-full absolute -bottom-1.5 -right-1.5" />
              </router-link>
            </div>
          </div>
        </div>
      </li>
      <li>
        <router-link to="/admin/device-templates/new" class="block bg-ifgreen hover:bg-ifgreen-dark text-white" >
          <div class="px-4 py-6 sm:px-6 flex justify-center">
            <PlusIcon class="h-6 w-6 mr-2" aria-hidden="true" />
            {{$t("device.addDeviceTemplate")}}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
// import { api } from "@/assets/js/api-client.js";
import { PlusIcon, PencilIcon, HomeIcon, ClockIcon, ShieldCheckIcon, ClipboardDocumentListIcon } from "@heroicons/vue/24/outline";
import { mapState } from "vuex";

export default {
  components: {
    PlusIcon,
    PencilIcon,
    HomeIcon,
    ClockIcon,
    ShieldCheckIcon,
    ClipboardDocumentListIcon
  },
  computed: {
    ...mapState(["organisation", "deviceTemplates"]),
  },
  methods: {
    async getDeviceTemplates() {
      this.$store.dispatch("setLoading", true);
      await this.$store.dispatch("getDeviceTemplates");
      this.$store.dispatch("setLoading", false);
    },
  },
  watch: {
    organisation() {
      this.getDeviceTemplates();
    }
  },
};
</script>
