<template>
  <div class="flex flex-col sm:flex-row sm:nowrap sm:justify-between mt-3">
      <div class="flex flex-col justify-between w-full sm:w-6/12 py-2 sm:mr-2">
          <label for="content-type" class=" block text-sm font-medium pb-1 sm:text-sm text-gray-700">{{$t("settings.fileType")}}</label>
          <select v-model="selectedType" id="content-type" style="height:50px" name="model" class=" block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm rounded-md">
              <option value="document">{{$t("settings.documents")}}</option>
              <option value="youtube">{{$t("settings.videos")}}</option>
          </select>
      </div>
      <div class="flex flex-col justify-between w-full sm:w-6/12 py-2 sm:ml-2" >
          <label for="content-type" class=" block text-sm font-medium pb-1 sm:text-sm text-gray-700">{{$t("language")}}</label>
          <select v-model="language" id="content-type" style="height:50px" name="model" class=" block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm rounded-md">
              <option v-for="lang in $i18n.availableLocales" :key="lang" :value="lang">{{ lang }}</option>
          </select>
      </div>
  </div>
  <div v-if="folders" class="flex flex-col sm:flex-row sm:nowrap sm:justify-between mt-3">
      <div class="flex flex-col justify-between w-full sm:w-6/12 py-2 sm:mr-2 sm:p-0">
          <label for="folder" class=" block text-sm font-medium pb-1 sm:text-sm text-gray-700">{{$t("settings.folder")}}</label>
          <select v-model="selected" id="folder" style="height:50px" name="model" class=" block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm rounded-md">
              <option v-if="!selected" :value="null" selected>{{$t("settings.noFolder")}}</option>
              <option v-for="(folder,index) in filteredFolders" :key="index" :value="folder">{{folder.folderName}}</option>
          </select>
      </div>
      <div class="flex flex-col justify-between w-full sm:w-6/12 sm:ml-2">
          <div class="w-full py-2 sm:p-0">
            <div>
              <label for="new-folder" class="pb-1 block text-sm font-medium text-gray-700">{{$t("settings.addFolder")}}</label>
            </div>
            <div class="flex rounded-md shadow-sm">
              <div class="relative flex flex-col items-stretch flex-grow focus-within:z-10">
                <input v-model="newFolder" type="text" name="text" id="new-folder" class=" h-full focus:ring-ifgreen focus:border-ifgreen block w-full rounded-none rounded-l-md sm:text-sm border-gray-300" :placeholder="$t('settings.folderName')" />
              </div>
              <button @click="addFolder" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-3 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
                <PlusIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          </div>
      </div>
  </div>
  <div v-if="selected">
      <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 mt-6 mb-6">
          <li class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <div class="w-full p-6 space-y-1">
                  <div class="bg-white pb-5 border-b border-gray-200 ">
                      <div class="flex items-center justify-between flex-wrap sm:flex-nowrap">
                          <div class="w-100 flex nowrap justify-between pb-3 sm:pb-0">
                              <h3 id="folder-name" :class="[isActive === true ? 'hidden' : '' , 'text-lg leading-6 font-medium text-gray-900']">
                                  {{selected ? selected.folderName : $t("settings.noFolder")}}
                              </h3>
                              <input v-model="editFolder" type="text" name="text" id="edit-folder" :class="[isActive === true ? '' : 'hidden', 'mr-1 pr-2 h-full focus:ring-ifgreen focus:border-ifgreen block w-full rounded-none rounded-md sm:text-sm border-gray-300']"/>
                          </div>
                          <div class="sm:pl-2">
                              <span class="relative z-0 inline-flex shadow-sm rounded-md">
                                  <button @click="showInput" type="button" id="edit-icon" :class="[isActive === true ? 'hidden' : '', 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen' ]" class="">
                                      <PencilIcon class='h-5 w-5' aria-hidden="true" />
                                  </button>
                                  <button @click="renameFolder(selected.id)" type="button" id="confirm-icon" :class="[isActive === true ? '' : 'hidden', 'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen']">
                                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </button>
                                  <button @click="openModal" type="button" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
                                      <TrashIcon class="h-5 w-5" aria-hidden="true" />
                                  </button>
                              </span>
                          </div>
                      </div>
                  </div>
                  <div class="bg-white py-5 border-b border-gray-200">
                      <div class="flex justify-between mb-2">
                        <div>
                          <RadioGroup
                            :choices="fileTypes"
                            v-model="fileType"
                            :wide="true"
                          />
                        </div>
                        <button @click="addFile(selected.id)" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                          <PlusIcon class="h-5 w-5 mr-0.5" />
                          {{$t("settings.addFile")}}
                        </button>
                      </div>
                      <div v-if="files && fileType == 'internal'">
                        <FileSelect
                          :label="$t('settings.chooseFile')"
                          v-model="newFile"
                          :files="files"
                        />
                      </div>
                      <div v-else class="flex rounded-md shadow-sm flex flex-col items-end w-full gap-y-1.5">
                          <div class="relative flex items-stretch flex-grow focus-within:z-10 w-full">
                              <input v-model="newFileTitle" type="text" name="text" id="file-title" class="focus:ring-ifgreen focus:border-ifgreen block w-full rounded-none rounded-md sm:text-sm border-gray-300" :placeholder="$t('settings.addFileTitle')" />
                          </div>
                          <div class="relative flex items-stretch flex-grow focus-within:z-10 w-full">
                              <input v-model="newFileLink" type="text" name="text" id="file-link" class="focus:ring-ifgreen focus:border-ifgreen block w-full rounded-none rounded-md sm:text-sm border-gray-300" :placeholder="$t('settings.addFileLink')" />
                          </div>
                          <p v-if="!urlMatch && newFileLink.length" class="pb-1 block text-xs font-normal text-red-500">{{$t("settings.fixUrl")}}</p>
                      </div>
                  </div>
                  <draggable
                    v-if="selected"
                    v-model="selected.folderContent"
                    :group="selected.folderName"
                    @start="drag=true"
                    @end="drag=false"
                    :item-key="(element) => { return element.title }"
                  >
                    <template #item="{element, index}">
                      <li class="py-4">
                        <div class="flex items-center justify-between space-x-4">
                          <div class="flex-1 min-w-0 cursor-move">
                            <a :href="element.link" target="_blank" class="text-sm font-medium text-gray-900">
                                {{element.title}}
                            </a>
                          </div>
                          <div class="flex items-center">
                            <button @click="removeFile(selected.id, index)" class=" inline-flex">
                                <MinusCircleIcon class="w-6 h-6" />
                            </button>
                          </div>
                        </div>
                      </li>
                    </template>
                  </draggable>
              </div>
          </li>
      </ul>
  </div>
  <Modal
      ref="modal"
      title=""
      :text="$t('public.infos.deleteFolderWarning')"
      :cancel-button-label="$t('cancel')"
  >
      <button type="button" @click="removeFolder(selected.id)" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm">
          {{$t("delete")}}
      </button>
  </Modal>
</template>
<script>
import { PlusIcon, PencilIcon, TrashIcon, CheckIcon, MinusCircleIcon } from "@heroicons/vue/24/outline";
import Modal from '@/components/layout/Modal.vue';
import RadioGroup from '@/components/inputs/RadioGroup.vue'
import FileSelect from '@/components/inputs/FileSelect.vue'
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { s3url } from "@/assets/js/helpers.js";
import { mapState } from 'vuex'
import draggable from 'vuedraggable'

export default {
  components: {
    PlusIcon,
    PencilIcon,
    TrashIcon,
    CheckIcon,
    MinusCircleIcon,
    Modal,
    RadioGroup,
    FileSelect,
    draggable
  },
  data () {
    return{
      folders: null,
      selected: null,
      newFolder: null,
      isActive: null,
      newFile: null,
      newFileLink: '',
      newFileTitle: null,
      doRemove: null,
      selectedType: 'document',
      drag: false,
      language: null,
      fileType: 'internal',
      files: null
    }
  },
  computed: {
    ...mapState(['organisation']),
    urlMatch(){
        const re = new RegExp("^(http|https)://", "i")
        console.log(this.newFileLink, re.test(this.newFileLink))
        return re.test(this.newFileLink)
    },
    filteredFolders(){
        return this.folders.filter((folder) => {
            return folder.contentType === this.selectedType
        })
    },
    fileTypes() {
      return [
        {value: 'internal', name: `${this.$t('settings.fromLibrary')}`},
        {value: 'external', name: `${this.$t('settings.external')}`}
      ]
    }
  },
  methods: {
    getSupportMaterials(){
      this.$store.dispatch('setLoading', true)
      api.get(`/organisations/${this.organisation}/support_materials?language=${this.language}`).then(result => {
        console.log(result)
        this.folders = result.data
        if (this.folders.length){
            this.selected = this.filteredFolders[0]
        } else {
          this.selected = null
        }
        this.$store.dispatch('setLoading', false)
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setLoading', false)
      })
    },
    getFiles(){
      api.get(`/organisations/${this.organisation}/files?language=${this.language}`).then(result => {
        console.log(result)
        this.files = result.data
      }).catch(error => {
        console.log(error)
      })
    },
    addFolder() {
         this.$store.dispatch('setLoading', true)
         const newFolder = {folderName: this.newFolder, contentType: this.selectedType, organisation: `/organisations/${process.env.VUE_APP_ORG_ID}`, language: this.language}
         api.post(`/support_materials`, newFolder).then(result => {
           console.log(result)
           newFolder['id'] = result.data.id
           newFolder['folderContent'] = []
           this.folders.push(newFolder)
           this.selected = newFolder
           this.newFolder = ''
           this.$store.dispatch('setLoading', false)
         })
     },
     showInput(){
         this.isActive = true
     },
     renameFolder(id) {
         this.isActive = false
         this.$store.dispatch('setLoading', true)
         api.patch(`/support_materials/${id}`, {folderName: this.editFolder}, patchHeaders).then(result => {
           console.log(result)
           this.selected.folderName = this.editFolder
           const index = this.folders.findIndex((folder) => {
               return folder.id === this.selected.id
           })
           this.folders[index].folderName = this.editFolder
           this.$store.dispatch('setLoading', false)
         })
     },
     openModal(){
         this.$refs.modal.show()
     },
     removeFolder(id){
         this.$refs.modal.close()
         this.$nextTick(() => { this.$store.dispatch('setLoading', true) })
         api.delete(`/support_materials/${id}`).then(result => {
           console.log(result)
           const index = this.folders.findIndex((folder) => {
               return folder.id === id
           })
           this.folders.splice(index, 1)
           this.selected = this.filteredFolders[0]
           this.doRemove = null
           this.$store.dispatch('setLoading', false)
         })
     },
     addFile(id){
        let type = null
        if (this.fileType == 'internal') {
          this.newFileLink = `${s3url}${this.newFile.path}`
          this.newFileTitle = this.newFile.name
          type = this.newFile.type
        }
        if(this.urlMatch){
          this.$store.dispatch('setLoading', true)
             this.selected.folderContent.push({ title: this.newFileTitle, link: this.newFileLink, type })
             console.log(this.selected)
             api.patch(`/support_materials/${id}`, this.selected, patchHeaders).then(result => {
               console.log(result)
               this.$store.dispatch('setLoading', false)
               this.newFileLink = ''
               this.newFileTitle = ''
               this.newFile = null
             })
         }
     },
     removeFile(id, fileIndex){
       this.$store.dispatch('setLoading', true)
       const folderIndex = this.folders.findIndex((folder) => {
           return folder.id === id
       })
       this.folders[folderIndex].folderContent.splice(fileIndex,1)
       api.patch(`/support_materials/${id}`, this.selected, patchHeaders).then(result => {
         console.log(result)
         this.$store.dispatch('setLoading', false)
       })
     }
  },
  created() {
    this.language = this.$i18n.locale
    console.log(this.language)
    this.getSupportMaterials()
  },
  watch: {
    organisation () {
      this.getSupportMaterials()
      this.getFiles()
    },
    language () {
      console.log(this.language)
      this.getSupportMaterials()
      this.getFiles()
    },
    selected: {
      deep: true,
      handler(newFolder,oldFolder){
        this.editFolder = this.selected ? this.selected.folderName : null
        if (oldFolder && newFolder && (oldFolder.folderName === newFolder.folderName)) {
          api.patch(`/support_materials/${this.selected.id}`, this.selected, patchHeaders).then(result => {
            console.log(result)
          })
        }
      }
    },
    selectedType: {
      deep: true,
      handler(){
        this.selected = this.filteredFolders[0]
      }
    },
    newFile () {
      console.log(this.newFile)
    }
  }
}
</script>
