<template>
    <div class="border-b border-gray-200 sticky top-16 z-10 bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <nav class="-mb-px block md:flex justify-between md:space-x-4">
          <div class="flex space-x-4 md:space-x-8" aria-label="Tabs">
              <a v-for="tab in tabs" @click="changeTab(tab.name)" :key="tab.name" :class="[tab.name === currentTab ? 'border-ifgreen text-ifgreen' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 'cursor-pointer whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm']">
              {{ tab.label }}
              </a>
          </div>
          </nav>
      </div>
    </div>
    <div v-if="currentTab === 'workers'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <ul v-if="workers" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-6 pb-12">
          <AddOption
              v-for="step in workers"
              :key="step.id"
              :id="step.id"
              :heading="workersLabels[step.stepNumber]"
              :name="step.id"
              :initialOptions="step.workers"
              @options-change="optionsChange($event, 'production_steps')"
          />
        </ul>
    </div>
    <div v-if="currentTab === 'support'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <SupportMaterials />
    </div>
    <div v-if="currentTab === 'deviceTemplates'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <DeviceTemplates />
    </div>
    <div v-if="currentTab === 'registrationKeys'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <RegistrationKeys />
    </div>
    <div v-if="currentTab === 'fileLibrary'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <FileLibrary />
    </div>

</template>
<script>
    import AddOption from '@/components/admin/AddOption.vue';
    import SupportMaterials from '@/components/admin/SupportMaterials.vue';
    import RegistrationKeys from '@/components/admin/RegistrationKeys.vue';
    import FileLibrary from '@/components/admin/FileLibrary.vue';
    import DeviceTemplates from '@/components/admin/DeviceTemplate/Templates.vue';
    import { api, patchHeaders } from "@/assets/js/api-client.js";
    import { mapState } from 'vuex'

    export default {
        components: {
            AddOption,
            SupportMaterials,
            DeviceTemplates,
            RegistrationKeys,
            FileLibrary
        },
        data (){
            return{
                options: null,
                workers: null,
                currentTab: 'support'
            }
        },
        computed: {
            ...mapState(['role','organisation']),
            settingsLabels () {
              return {
                model: 'Model szafy',
                heatpump: 'Pompa ciepła',
                version: 'Wersja',
                power: 'Moc',
                bypass: 'Bypass'
              }
            },
            workersLabels () {
              return {
                2: `${this.$t("settings.stage2")}`,
                3: `${this.$t("settings.stage3")}`,
                4: `${this.$t("settings.stage4")}`,
                5: `${this.$t("settings.stage5")}`,
                6: `${this.$t("settings.stage6")}`,
                7: `${this.$t("settings.stage7")}`,
                8: `${this.$t("settings.stage8")}`,
                9: `${this.$t("settings.stage9")}`,
              }
            },
            tabs () {
              return [
                { label: `${this.$t("settings.workers")}`, name: 'workers', roles: ['superadmin'], enabled: true},
                { label: `${this.$t("settings.support")}`, name: 'support', roles: ['superadmin', 'admin'], enabled: true},
                { label: `${this.$t("settings.deviceTemplates")}`, name: 'deviceTemplates', roles: ['superadmin', 'admin'], enabled: true},
                { label: `${this.$t("settings.fileLibrary")}`, name: 'fileLibrary', roles: ['superadmin', 'admin'], enabled: true},
                { label: `${this.$t("settings.registrationKeys")}`, name: 'registrationKeys', roles: ['superadmin','admin'], enabled: parseInt(process.env.VUE_APP_ENABLE_REGISTRATION_KEYS)}
              ].filter(tab => {return tab.enabled && tab.roles.includes(this.role.name)})
            },
        },
        methods: {
            getParameters() {
              this.$store.dispatch('setLoading', true)
              api.get(`/production_parameters`).then(result => {
                console.log(result)
                this.options = result.data
                this.$store.dispatch('setLoading', false)
              }).catch(error => {
                console.log(error)
                this.$store.dispatch('setLoading', false)
              })
            },
            getWorkers() {
              this.$store.dispatch('setLoading', true)
              api.get(`/production_steps?order[stepNumber]`).then(result => {
                this.workers = result.data
                console.log(result)
                this.$store.dispatch('setLoading', false)
              }).catch(error => {
                console.log(error)
                this.$store.dispatch('setLoading', false)
              })
            },
            changeTab(tab) {
              this.currentTab = tab
              this.$router.push({ hash: `#${this.currentTab}`})
              switch (tab) {
                case 'production':
                  if (!this.options) {
                    this.getParameters()
                  }
                  break
                case 'workers':
                  if (!this.workers) {
                    this.getWorkers()
                  }
                  break
                case 'support':
                  break
              }
            },
            optionsChange(data, res){
              if (res == 'production_steps') {
                data['workers'] = data['options']
                delete data['options']
              }
              console.log(data)
              api.patch(`/${res}/${data.id}`, data, patchHeaders).then(result => {
                console.log(result)
              })
            }
        },
        watch: {
          $route: {
            immediate: true,
            handler() {
              const tab = this.tabs.find(tab => {return tab.name == this.$route.hash.substring(1)})
              if (tab) this.changeTab(tab.name)
            },
          }
        }
    }
</script>
