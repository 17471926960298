<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden z-50" @close="open = false" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        {{$t("keys.addRegistrationKeys")}}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ifgreen" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <p class="text-xs mb-6">{{$t("keys.keyInfo")}}</p>
                    <div>
                      <label for="keys" class="block text-sm font-medium text-gray-700">
                        {{$t("keys.keys")}}
                      </label>
                      <div class="mt-1 relative">
                        <textarea
                          id="keys"
                          name="keys"
                          class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
                          v-model="newKeys"
                          rows="20"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen" @click="open = false">
                    {{$t("cancel")}}
                  </button>
                  <button :disabled="!this.newKeys" @click="submitKeys" type="submit" :class="!this.newKeys ? 'pointer-events-none opacity-60' : ''" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                    {{$t("add")}}
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { XMarkIcon, TrashIcon, CogIcon } from '@heroicons/vue/24/outline'
import UserSelect from '@/components/inputs/UserSelect.vue'
import { api } from "@/assets/js/api-client.js";
import { mapState } from 'vuex'

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        XMarkIcon,
        TrashIcon,
        CogIcon,
        UserSelect
    },
    props: {
      keys: {
        type: Array
      }
    },
    data () {
      return {
        open: false,
        newKeys: null
      }
    },
    computed: {
      ...mapState(['organisation'])
    },
    methods: {
        show () {
          this.open = true
        },
        submitKeys () {
          this.open = false
          this.$nextTick(() => {
            this.$store.dispatch('setLoading', true)
          })

          const keys = this.newKeys.split('\n')
            .map(newKey => {return newKey.trim()})
            .filter(newKey => {
              const duplicate = this.keys.find(key => {return key.keyString === newKey})
              return !duplicate && newKey.length > 0
            })

          const data = {
            keys,
            organisation: `/organisations/${this.organisation}`
          }

          api.post('/registration_keys/multiple', data).then((result) => {
            console.log(result)
            this.$emit('refresh-keys')
          }).catch(err => {
            console.log(err);
            this.$store.dispatch('setLoading', false)
          })
        }
    },
}
</script>
