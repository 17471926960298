<template>
  <div class="mt-6">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">{{$t("keys.registrationKeys")}}</h1>
        <p class="mt-2 text-sm text-gray-700">{{$t("keys.manageKeys")}}</p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button @click="openModal" type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-ifgreen px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-iifgreen-dark focus:outline-none focus:ring-2 focus:ring-ifgreen focus:ring-offset-2 sm:w-auto">{{$t("keys.addKeys")}}</button>
      </div>
    </div>
    <div v-if="registrationKeys" class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{$t("keys.key")}}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{$t("service.status")}}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{$t("keys.user")}}</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6 text-right">
                    {{$t("action")}}<span class="sr-only">{{$t("delete")}}</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr v-for="(key, index) in registrationKeys" :key="key.keyString" :class="index % 2 === 0 ? undefined : 'bg-gray-50'">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ key.keyString }}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-if="!key.user" title="Wolny" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                      {{$t("keys.available")}}
                    </span>
                    <span v-else title="Wykorzystany" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                      {{$t("keys.used")}}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ key.user ? key.user.name : 'brak' }}</td>
                  <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <button v-if="!key.user" title="Usuń klucz" @click="deleteKey(key.id, index)" class="rounded-full inline-flex items-center bg-ifgreen text-white px-1 py-1 hover:bg-ifgreen-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ifgreen">
                      <CogIcon v-if="loading === index" class="h-5 w-5 animate-spin" aria-hidden="true" />
                      <TrashIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddRegistrtionKeys ref="addKeysModal" :keys="this.registrationKeys" @refresh-keys="getRegistrationKeys" />
</template>
<script>

import { api } from "@/assets/js/api-client.js";
import { TrashIcon, CogIcon } from '@heroicons/vue/24/outline'
import AddRegistrtionKeys from '@/components/admin/AddRegistrationKeys.vue'
import { mapState } from 'vuex'

export default {
  components: {
    AddRegistrtionKeys,
    TrashIcon,
    CogIcon
  },
  data () {
    return{
      registrationKeys: null,
      loading: -1
    }
  },
  computed: {
    ...mapState(['organisation']),
  },
  methods: {
    getRegistrationKeys(){
      this.$store.dispatch('setLoading', true)
      api.get(`/organisations/${this.organisation}/registration_keys`).then(result => {
        console.log(result)
        this.registrationKeys = result.data
        this.$store.dispatch('setLoading', false)
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('setLoading', false)
      })
    },
    deleteKey (id, index) {
      this.loading = index
      api.delete(`/registration_keys/${id}`).then(result => {
        console.log(result)
        this.registrationKeys.splice(index, 1)
        this.loading = -1
      }).catch(error => {
        console.log(error)
        this.loading = -1
      })
    },
    openModal () {
      this.$refs.addKeysModal.show()
    }
  },
  created() {
    this.getRegistrationKeys()
  },
  watch: {
    organisation () {
      this.getRegistrationKeys()
    }
  }
}
</script>
